import Autocomplete from '@trevoreyre/autocomplete-js';
import imagesLoaded from 'imagesloaded';
import Isotope from 'isotope-layout';
import bootstrap from 'bootstrap';

// 
// user.js
// Use this to write your custom JS
//

(() => {
    let searchBoxes = document.querySelectorAll('.autocomplete');
    searchBoxes.forEach((searchBox) => {
        new Autocomplete(('#' + searchBox.getAttribute('id')), {
            search: (input) => {
                const url = "/search/suggest";

                return new Promise((resolve) => {
                    if (input.length < 2) return resolve([]);

                    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ "query": input, "type": searchBox.getAttribute('data-type') }) })
                        .then((response) => response.json())
                        .then((data) => {
                            let options = [];

                            ['completions', 'name'].forEach(d => {
                                data[d].forEach((e) => {
                                    options = options.concat(e.options.filter(function (item, pos, self) {
                                        return options.some(val => val._id === item._id) == false;
                                    }));
                                });
                            });

                            resolve(options);
                        })
                        .catch((error) => {
                            resolve([]);
                        });
                });
            },
            getResultValue: result => result._source.name || result.text,
            onSubmit: d => {
                if (searchBox.data('anchor')) {
                    let slug = d._source.slug;
                    location.hash = slug.substring(slug.indexOf('#'));
                }
            },
            renderResult: (result, props) => {
                if (result._source) {
                    return `<li ${props}><a href="${result._source.slug}">${result._source.name || result.text}</a></li>`;
                } else {
                    return `<li ${props}>${result.text}</li>`;
                }
            },
            debounceTime: 150,
            autoSelect: false
        });
    });
})();

/*** Menu ***/

(() => {
    let menuItems = document.querySelectorAll('#menuTabs .nav-link');

    window.addEventListener('hashchange', function (e) {
        e.preventDefault();
    });

    menuItems.forEach(function (item) {
        item.addEventListener('click', function (e) {
            e.preventDefault();

            let target = e.currentTarget.dataset.bsTarget;

            if (history.pushState) {
                history.pushState(null, null, target);
            } else {
                location.hash = target;
            }

            if (['', '#gallery', '#about'].includes(target))
                Gallery();
        });
    });

    let otherTabs = document.querySelectorAll('[data-tabto]');

    otherTabs.forEach((tab) => {
        tab.addEventListener('click', (e) => {
            e.preventDefault();
            goToTab(tab.dataset.tabto, true);
        });
    });

    const goToTab = (name, scrollIntoView) => {
        let triggerEl = document.querySelector('#menuTabs .nav-link[data-bs-target="' + name + '"]');

        let pane = new window.Tab(triggerEl);
        pane.show() // Select tab by name

        if (scrollIntoView)
            document.getElementById(name.substring(1))?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    if (location.hash)
        goToTab(location.hash);
})();

const Gallery = () => {
    const grids = document.querySelectorAll('[data-isotope]');

    grids.forEach(function (grid) {
        imagesLoaded(grid, function () {
            const options = grid.dataset.isotope ? JSON.parse(grid.dataset.isotope) : {};

            new Isotope(grid, options);
        });
    });
};
Gallery();

const GoogleMap = () => {
    let maps = document.querySelectorAll('[data-lat][data-lng]');

    maps.forEach(function (map) {

        // Get map data
        var mapLat = parseFloat(map.dataset.lat);
        var mapLng = parseFloat(map.dataset.lng);
        var mapZoom = parseInt(map.dataset.zoom, 10);

        // Create a map object
        var gMap = new google.maps.Map(map, {
            center: {
                lat: mapLat,
                lng: mapLng
            },
            zoom: mapZoom,
            mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain', 'styled_map']
            },
            disableDefaultUI: false,
            scrollwheel: false
        });

        // Create a marker
        var marker = new google.maps.Marker({
            position: {
                lat: mapLat,
                lng: mapLng
            },
            map: gMap,
            visible: true
        });
    });
};
GoogleMap();