import {
  Collapse,
  Tab,
  Dropdown
} from 'bootstrap';

// Make available globally
window.Collapse = Collapse;
window.Dropdown = Dropdown;
window.Tab = Tab;
